import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import Link from "next/link";
import { VFC } from "react";
import { GET_NEWS } from "../../queries/queries";
import { GetNewsQuery } from "../../types/generated/graphql";

export const Index: VFC = () => {
  const { data, error, loading } = useQuery<GetNewsQuery>(GET_NEWS, {
    fetchPolicy: "cache-and-network",
  });
  return (
    <div className="l-main__inner">
      <p className="home-hdLogo">
        <a href="/">
          <img src="/assets/img/common/logo.svg" alt="ルーツしまね" />
        </a>
      </p>

      <p className="home-join">
        <Link href="/regist">
          <a className="u-over">
            <img src="/assets/img/home/join-txt.svg" alt="JOIN" />
          </a>
        </Link>
      </p>

      <section className="home-kv">
        <div className="home-kv__inner">
          <div className="home-kv__bg">
            <div
              className="home-kv__bgImg u-pc"
              style={{ backgroundImage: "url(/assets/img/home/kv-bg.jpg)" }}
            ></div>
            <div className="home-kv__bgAni u-sp">
              <span
                style={{ backgroundImage: "url(/assets/img/home/kv-bg.jpg)" }}
              ></span>
              <span
                style={{ backgroundImage: "url(/assets/img/home/kv-bg.jpg)" }}
              ></span>
            </div>
          </div>
        </div>
      </section>

      <section className="home-msg">
        <div className="home-msg__fix">
          <div className="l-container">
            <div className="home-msg__inner">
              <div className="home-msg__ttl">
                <h2>
                  島根<span>の</span>高校<span>を</span>卒業<span>した</span>
                  <br />
                  しまね<span>に</span>ルーツ<span>の</span>あるあなた
                  <span>へ</span>
                </h2>
                <p>
                  <img src="/assets/img/home/msg-logo.svg" alt="" />
                </p>
              </div>

              <div className="home-msg__txt">
                <p>
                  島根を出てしまったけれど、
                  <br />
                  大学を超えた新しいコミュニティや友達がほしい。
                  <br />
                  <br />
                  島根にいるけれど、
                  <br />
                  県外に出た同世代や社会人の先輩と出会いたい。
                  <br />
                  <br />
                  就職活動をしなきゃいけないけれど、
                  <br />
                  島根に帰るか、都会で就職するか迷っている。
                  <br />
                  社会人の先輩の話が聞きたいけど、どうせなら島根の先輩に出会いたい。
                  <br />
                  <br />
                  都会で就職しようと思うけれど、
                  <br />
                  いつか帰りたいから、今から島根で繋がりを作っておきたい。
                  <br />
                  帰るつもりはないけど、島根と関わり続けたい。
                  <br />
                  <br />
                  だから、ルーツしまねで、
                  <br />
                  島根との関わり方を考えてみませんか？
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home-hito">
        <div className="home-hito__inner">
          <div className="home-ttl">
            <h2>
              <span>
                どんな人が
                <b className="u-sp">
                  <br />
                </b>
                関わっているの？
              </span>
            </h2>
          </div>

          <div className="home-hito__bg">
            <span
              style={{ backgroundImage: "url(/assets/img/home/hito-bg.png)" }}
            ></span>

            <div className="home-hito__bgInner">
              <div className="home-hito__cards u-pc">
                <div className="home-hito__card -n1">
                  <div className="u-zoom">
                    <h3>
                      いつか島根に帰って
                      <br />
                      貢献できるように、
                    </h3>
                    <p>
                      もっと広い世界を見ようと都会の大学に進学した。でも、島根に帰ることだけがゴールではないと、都会から島根に関わることで気づいた。
                      <br />
                      島根にいても外にいてもおもしろいと思える島根にするために、島根の外からでも島根に関われるあり方を模索し続ける。
                    </p>
                  </div>
                </div>
                <div className="home-hito__card -n2">
                  <div className="u-zoom">
                    <h3>
                      田舎が嫌いで、
                      <br />
                      島根を出て
                      <br />
                      都会の大学に進学した。
                    </h3>
                    <p>
                      都会での大学生活は楽しかったけれど、このまま都会で働くのか迷っていた。そんな時、島根で楽しそうに働く大人と出会って、こんな大人になりたいと思った。この人たちと一緒に働きたいと思い、大学を休学して1年間インターン、その後も島根で暮らしていくことにした。
                    </p>
                  </div>
                </div>
                <div className="home-hito__card -n3">
                  <div className="u-zoom">
                    <h3>
                      まちづくりを勉強したくて
                      <br />
                      島根の大学に進学した。
                    </h3>
                    <p>
                      地域で活動していく中で、住んでいるまちをよくしようと意思を持って働く大人と出会い、自分も地元でそんな風に働きたいと思った。
                      <br />
                      その後、まちづくりを更に学ぶために県外の大学院に進学。将来島根で活躍するために、新卒で都会の会社に就職して自分を磨こうと思う。
                    </p>
                  </div>
                </div>
                <div className="home-hito__card -n4">
                  <div className="u-zoom">
                    <h3>
                      東京で生まれ育ち、
                      <br />
                      しまね留学で
                      <br />
                      島根に初めて来た。
                    </h3>
                    <p>
                      都会にはない地域ならではの経験は、毎日が楽しく新鮮だった。
                      <br />
                      高校時代にお世話になった大人や仲間と離れたくないと思い、島根の大学に進学した。島根での経験や大人との出会いによって、島根で活躍したいと思うようになり、卒業後も島根で就職し暮らしていくことにした。
                    </p>
                  </div>
                </div>
              </div>

              <div className="home-hito__slider u-sp">
                <div className="home-hito__slick">
                  <div className="home-hito__slickSlide">
                    <div className="home-hito__sliderSlide">
                      <div className="home-hito__card -n1">
                        <a className="u-zoom">
                          <h3>
                            いつか島根に帰って
                            <br />
                            貢献できるように、
                          </h3>
                          <p>
                            もっと広い世界を見ようと都会の大学に進学した。でも、島根に帰ることだけがゴールではないと、都会から島根に関わることで気づいた。
                            <br />
                            島根にいても外にいてもおもしろいと思える島根にするために、島根の外からでも島根に関われるあり方を模索し続ける。
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="home-hito__slickSlide">
                    <div className="home-hito__sliderSlide">
                      <div className="home-hito__card -n2">
                        <a className="u-zoom">
                          <h3>
                            田舎が嫌いで、
                            <br />
                            島根を出て
                            <br />
                            都会の大学に進学した。
                          </h3>
                          <p>
                            都会での大学生活は楽しかったけれど、このまま都会で働くのか迷っていた。そんな時、島根で楽しそうに働く大人と出会って、こんな大人になりたいと思った。この人たちと一緒に働きたいと思い、大学を休学して1年間インターン、その後も島根で暮らしていくことにした。
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="home-hito__slickSlide">
                    <div className="home-hito__sliderSlide">
                      <div className="home-hito__card -n3">
                        <a className="u-zoom">
                          <h3>
                            まちづくりを勉強したくて
                            <br />
                            島根の大学に進学した。
                          </h3>
                          <p>
                            地域で活動していく中で、住んでいるまちをよくしようと意思を持って働く大人と出会い、自分も地元でそんな風に働きたいと思った。
                            <br />
                            その後、まちづくりを更に学ぶために県外の大学院に進学。将来島根で活躍するために、新卒で都会の会社に就職して自分を磨こうと思う。
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="home-hito__slickSlide">
                    <div className="home-hito__sliderSlide">
                      <div className="home-hito__card -n4">
                        <a className="u-zoom">
                          <h3>
                            東京で生まれ育ち、
                            <br />
                            しまね留学で
                            <br />
                            島根に初めて来た。
                          </h3>
                          <p>
                            都会にはない地域ならではの経験は、毎日が楽しく新鮮だった。
                            <br />
                            高校時代にお世話になった大人や仲間と離れたくないと思い、島根の大学に進学した。島根での経験や大人との出会いによって、島根で活躍したいと思うようになり、卒業後も島根で就職し暮らしていくことにした。
                          </p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p className="home-hito__more">
                <Link href="/member/student">
                  <a className="u-zoom">More</a>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-koto">
        <div className="home-koto__inner">
          <div className="home-ttl">
            <h2>
              <span>
                まず何から
                <b className="u-sp">
                  <br />
                </b>
                始めたらいいの？
              </span>
            </h2>
          </div>

          <div className="l-container">
            <div className="home-koto__bg">
              <div className="home-koto__bgColor"></div>
              <div className="home-koto__bgInner">
                <div className="home-koto__cards">
                  <div className="home-koto__card">
                    <Link href="/event">
                      <a className="u-zoom">
                        <img src="/assets/img/home/koto-img1.jpg" alt="EVENT" />
                      </a>
                    </Link>
                  </div>
                  <div className="home-koto__card">
                    <Link href="/intern">
                      <a className="u-zoom">
                        <img
                          src="/assets/img/home/koto-img2.jpg"
                          alt="INTERN"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="home-koto__card">
                    <Link href="/article">
                      <a className="u-zoom">
                        <img
                          src="/assets/img/home/koto-img3.jpg"
                          alt="MAGAZINE"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="home-koto__card">
                    <Link href="/member/student">
                      <a className="u-zoom">
                        <img
                          src="/assets/img/home/koto-img4.jpg"
                          alt="MEMBER"
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="home-koto__card">
                    <Link href="/slack">
                      <a className="u-zoom">
                        <img src="/assets/img/home/koto-img5.jpg" alt="SLACK" />
                      </a>
                    </Link>
                  </div>
                </div>

                <div className="home-koto__btns">
                  <div className="home-koto__btn">
                    <Link href="/regist">
                      <a className="u-zoom">
                        <img src="/assets/img/home/koto-btn1.png" alt="JOIN" />
                      </a>
                    </Link>
                  </div>
                  <div className="home-koto__btn">
                    <a
                      href="https://lin.ee/cylP4fZ"
                      className="u-zoom"
                    >
                      <img src="/assets/img/home/koto-btn2.png" alt="LINE" />
                    </a>
                  </div>
                </div>

                <div className="home-koto__news">
                  <div className="home-koto__newsInner">
                    <h3>
                      <img
                        className="u-pc"
                        src="/assets/img/home/koto-news-ttl.png"
                        alt="NEWS"
                      />
                      <img
                        className="u-sp"
                        src="/assets/img/home/koto-news-ttl-sp.png"
                        alt="NEWS"
                      />
                    </h3>
                    <ul className="u-pc">
                      {data?.news.map((news, index) => (
                        <li key={news.id}>
                          {news.url ? (
                            <a
                              href={news.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {moment(news.date).format("YYYY.MM.DD")}{" "}
                              {news.comment}
                            </a>
                          ) : (
                            <p>
                              {moment(news.date).format("YYYY.MM.DD")}{" "}
                              {news.comment}
                            </p>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
