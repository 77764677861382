import { useQuery } from "@apollo/client";
import { useUser } from "@auth0/nextjs-auth0";
import { VFC } from "react";
import { Layout } from "../components/Layout";
import { Index } from "../components/front/Index";

const Home: VFC = () => {
  return (
    <Layout title="Home" home={true}>
      <Index />
    </Layout>
  );
};

export default Home;
