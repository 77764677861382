import { gql } from "@apollo/client";

export const GET_MEMBER = gql`
  query GetMember($auth0_id: String!) {
    members_by_pk(auth0_id: $auth0_id) {
      address
      affiliation
      auth0_id
      birthday
      birthplace_city
      birthplace_state
      created_at
      faculty_department
      former_school
      id
      introduction
      manager_name
      name
      name_ruby
      occupation
      organization_name
      post_code_first
      post_code_second
      published_member_page
      sex
      university
      updated_at
      questionnaire
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember(
    $auth0_id: String!
    $address: String = ""
    $affiliation: String = ""
    $birthday: date = ""
    $birthplace_city: String = ""
    $birthplace_state: String = ""
    $faculty_department: String = ""
    $former_school: String = ""
    $name_ruby: String = ""
    $name: String = ""
    $occupation: String = ""
    $organization_name: String = ""
    $post_code_first: String = ""
    $post_code_second: String = ""
    $questionnaire: jsonb = ""
    $sex: String = ""
    $university: String = ""
  ) {
    update_members_by_pk(
      pk_columns: { auth0_id: $auth0_id }
      _set: {
        address: $address
        affiliation: $affiliation
        birthday: $birthday
        birthplace_city: $birthplace_city
        birthplace_state: $birthplace_state
        faculty_department: $faculty_department
        former_school: $former_school
        name_ruby: $name_ruby
        occupation: $occupation
        organization_name: $organization_name
        post_code_first: $post_code_first
        post_code_second: $post_code_second
        questionnaire: $questionnaire
        sex: $sex
        university: $university
        name: $name
      }
    ) {
      auth0_id
    }
  }
`;

export const CREATE_MEMBER = gql`
  mutation CreateMember(
    $address: String = ""
    $affiliation: String = ""
    $auth0_id: String = ""
    $birthday: date = ""
    $birthplace_city: String = ""
    $birthplace_state: String = ""
    $email: String = ""
    $faculty_department: String = ""
    $former_school: String = ""
    $introduction: String = ""
    $manager_name: String = ""
    $name: String = ""
    $name_ruby: String = ""
    $occupation: String = ""
    $organization_name: String = ""
    $post_code_first: String = ""
    $post_code_second: String = ""
    $questionnaire: jsonb = ""
    $sex: String = ""
    $university: String = ""
  ) {
    insert_members_one(
      object: {
        address: $address
        affiliation: $affiliation
        auth0_id: $auth0_id
        birthday: $birthday
        birthplace_city: $birthplace_city
        birthplace_state: $birthplace_state
        email: $email
        faculty_department: $faculty_department
        former_school: $former_school
        introduction: $introduction
        name: $name
        name_ruby: $name_ruby
        occupation: $occupation
        organization_name: $organization_name
        post_code_first: $post_code_first
        post_code_second: $post_code_second
        questionnaire: $questionnaire
        sex: $sex
        university: $university
      }
    ) {
      id
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEvents {
    events(where: { published: { _eq: true } }) {
      capacity
      created_at
      created_member_id
      detail
      end_at
      event_style
      fee
      fee_style
      id
      organizer_name
      place
      publication_end_date
      publication_start_date
      published
      start_at
      target_person
      thumbnail_url
      title
      updated_at
      zoom_url
      description
    }
  }
`;

export const GET_EVENT = gql`
  query GetEvent($id: uuid = "") {
    events_by_pk(id: $id) {
      created_at
      capacity
      created_member_id
      end_at
      detail
      event_style
      fee
      fee_style
      id
      organizer_name
      place
      publication_end_date
      publication_start_date
      published
      start_at
      target_person
      thumbnail_url
      title
      updated_at
      zoom_url
      icon_url
      event_reservations {
        member {
          auth0_id
        }
      }
    }
  }
`;

export const CREATE_EVENT_RESERVATION = gql`
  mutation CreateEventReservation($event_id: uuid = "", $member_id: uuid = "") {
    insert_event_reservations(
      objects: { event_id: $event_id, member_id: $member_id }
    ) {
      affected_rows
    }
  }
`;

export const GET_EVENT_RESERVATION = gql`
  query GetEventReservation($member_id: uuid = "", $event_id: uuid = "") {
    event_reservations(
      where: { member_id: { _eq: $member_id }, event_id: { _eq: $event_id } }
    ) {
      id
    }
  }
`;

export const GET_INTERNS = gql`
  query GetInterns {
    interns(where: { published: { _eq: true } }) {
      activity_support_amount
      activity_support_style
      capacity
      created_at
      created_member_id
      detail
      end_date
      id
      place
      publication_end_date
      publication_start_date
      published
      recipient_introduction
      recipient_message
      recipient_name
      start_date
      thumbnail_url
      title
      updated_at
      description
    }
  }
`;

export const GET_INTERN = gql`
  query GetIntern($id: uuid = "") {
    interns_by_pk(id: $id) {
      activity_support_amount
      activity_support_style
      capacity
      created_at
      created_member_id
      detail
      end_date
      id
      place
      publication_end_date
      publication_start_date
      published
      recipient_introduction
      recipient_message
      recipient_name
      start_date
      thumbnail_url
      title
      updated_at
      icon_url
    }
  }
`;

export const CREATE_INTERN_RESERVATION = gql`
  mutation CreateInternReservation(
    $intern_id: uuid = ""
    $member_id: uuid = ""
  ) {
    insert_intern_reservations_one(
      object: { member_id: $member_id, intern_id: $intern_id }
    ) {
      id
    }
  }
`;

export const GET_INTERN_RESERVATION = gql`
  query GetInternReservation($intern_id: uuid = "", $member_id: uuid = "") {
    intern_reservations(
      where: { intern_id: { _eq: $intern_id }, member_id: { _eq: $member_id } }
    ) {
      id
    }
  }
`;

export const GET_RESERVED_INTERNS = gql`
  query GetReservedInterns($auth0_id: String = "") {
    intern_reservations(where: { member: { auth0_id: { _eq: $auth0_id } } }) {
      intern {
        activity_support_amount
        activity_support_style
        capacity
        created_at
        created_member_id
        detail
        end_date
        id
        place
        publication_end_date
        publication_start_date
        published
        recipient_introduction
        recipient_message
        recipient_name
        start_date
        thumbnail_url
        title
        updated_at
        description
      }
    }
  }
`;

export const GET_RESERVED_EVENTS = gql`
  query GetReservedEvents($auth0_id: String = "") {
    event_reservations(where: { member: { auth0_id: { _eq: $auth0_id } } }) {
      event {
        capacity
        created_at
        created_member_id
        detail
        end_at
        event_style
        fee
        fee_style
        id
        organizer_name
        place
        publication_start_date
        publication_end_date
        published
        start_at
        target_person
        thumbnail_url
        title
        updated_at
        zoom_url
        description
      }
    }
  }
`;

export const GET_PUBLISHED_MEMBERS = gql`
  query GetPublishedMember($occupation: String = "社会人") {
    members(
      where: {
        published_member_page: { _eq: true }
        occupation: { _eq: $occupation }
      }
    ) {
      introduction
      name
      name_ruby
      profile_image_url
      affiliation
      birthplace_city
      birthplace_state
      former_school
      faculty_department
      university
    }
  }
`;

export const GET_NEWS = gql`
  query GetNews {
    news(order_by: { date: desc }) {
      comment
      id
      date
      url
    }
  }
`;
